import { Loading, LocalStorage, Notify, Platform, useId } from 'quasar'
import { $analytics } from 'boot/analytics'
// import { $fetch } from 'boot/fetch'
import { $logger } from 'boot/logger'
import { $pegApi } from 'boot/peg-api'
import { $proxy } from 'src/boot/proxy'
import { $rollbar } from 'boot/rollbar'
import { updateEmail, updateProfile } from 'firebase/auth'
import { MINUTE_MS } from 'src/utils/time.util'
import { addressIsComplete } from 'src/utils/validator.util'
import { Geolocation } from '@capacitor/geolocation'
import { $flagsmith } from 'boot/flagsmith'

export const useSessionStore = defineStore('session', () => {
  // const $q = useQuasar()
  const cartStore = useCartStore()
  const { isCartPage } = useRouteMap()

  /* ----- STATE ----- */
  const sessionKey = ref(useId())
  const authIsInitialized = ref(false)
  const userIsAuthenticated = ref(false)
  const user = ref({})
  const sessionAuthToken = ref(null)
  const userWatchlist = reactive({ items: [], totalCount: 0 })
  const shoppingZip = ref(LocalStorage.getItem('peg_geo')?.zip || '68144')
  const location = ref({ accuracy: null, latitude: null, longitude: null })
  const locationLoadingText = ref('Locating')
  const hasGrantedLocation = ref(false)

  // update key data on shopping zip change
  watch(shoppingZip, zip => {
    const geo = LocalStorage.getItem('peg_geo')
    // persist in session storage
    LocalStorage.setItem('peg_geo', { ...geo, zip })
    // force app to re-render
    refreshSessionKey()
  })

  /* ----- COMPUTED/GETTERS ----- */
  const baseProfile = computed(() => {
    return {
      email: user.value?.email || null,
      firstName: user.value?.firstName || null,
      lastName: user.value?.lastName || null,
      phone: user.value?.phone || null,
    }
  })
  const emailSmsConsent = computed(() => {
    return {
      emailOptIn: user.value?.emailOptIn,
      emailOptInMarketing: user.value?.emailOptInMarketing,
      smsOptIn: user.value?.smsOptIn,
      smsOptInMarketing: user.value?.smsOptInMarketing,
    }
  })
  const billingAddress = computed(() => {
    return {
      address1: user.value?.billingAddress1 || null,
      address2: user.value?.billingAddress2 || null,
      city: user.value?.billingCity || null,
      state: user.value?.billingState || null,
      zip: user.value?.billingZip || null,
    }
  })
  const shippingAddress = computed(() => {
    return {
      address1: user.value?.shippingAddress1 || null,
      address2: user.value?.shippingAddress2 || null,
      city: user.value?.shippingCity || null,
      state: user.value?.shippingState || null,
      zip: user.value?.shippingZip || null,
    }
  })
  const billingAddressCompleted = computed(() => addressIsComplete(billingAddress.value, { includeName: false }))
  const shippingAddressCompleted = computed(() => addressIsComplete(shippingAddress.value, { includeName: false }))
  const accountEmailVerified = computed(() => {
    return Boolean(user.value?.emailVerified)
  })
  const accountPaymentSaved = computed(() => {
    return Boolean(user.value?.paymentSaved)
  })
  const accountProfileCompleted = computed(() => {
    return Boolean(
      user.value?.email &&
        user.value?.firstName &&
        user.value?.lastName &&
        user.value?.phone &&
        billingAddressCompleted.value &&
        shippingAddressCompleted.value
    )
  })
  const accountTermsAccepted = computed(() => {
    return Boolean(user.value?.termsAccepted)
  })
  const accountBillingCompleted = computed(() => {
    return Boolean(user.value?.billingCompleted)
  })

  const accountSetupCompleted = computed(() => {
    return Boolean(
      !userIsAuthenticated.value || (accountTermsAccepted.value && accountProfileCompleted.value && accountBillingCompleted.value)
    )
  })

  const userIsInPenaltyBox = computed(() =>
    Boolean(user.value.inPenaltyBox && (!user.value.defaultPaymentToken || !user.value.autoCheckout))
  )

  const userIsInternal = computed(() => Boolean(user.value?.isInternal))

  const sessionIsLockedDown = computed(() =>
    Boolean(
      ($flagsmith.hasFeature('internal_only') || import.meta.env.VITE_APP_ID.includes('test')) &&
        !Platform.is.nativeMobile &&
        !userIsInternal.value
    )
  )
  const hasVerifiedPhone = computed(() => Boolean(user.value?.phoneVerified))

  const hasValidLocation = computed(() => location.value.latitude !== null && location.value.longitude !== null)

  /* ----- ACTIONS ----- */

  /**
   * Refresh the session key - forces main page content to be re-rendered
   */
  function refreshSessionKey() {
    // capture scroll position before refresh so we can restore it
    const scrollY = window.scrollY

    sessionKey.value = useId()

    // re-fetch user data if user is authenticated
    if (userIsAuthenticated.value) {
      $pegApi.Users.get().then(({ user: updatedUser }) => {
        Object.assign(user.value, updatedUser)
      })
    }

    // restore scroll position
    setTimeout(() => {
      window.scrollTo({ top: scrollY, behavior: 'smooth' })
    }, 500)
  }

  /**
   * Set the auth token for the user
   * @param {Object} firebaseUser - Firebase user object
   */
  async function setAuthToken(firebaseUser) {
    if (firebaseUser) {
      sessionAuthToken.value = await firebaseUser.getIdToken(true)
      // $logger.debug('AUTH TOKEN', sessionAuthToken.value)

      // refresh token every 30 minutes
      setTimeout(() => setAuthToken(firebaseUser), MINUTE_MS * 30)
    } else {
      sessionAuthToken.value = null
    }
  }

  /**
   * Login user and fetch user data from PEG API
   * @param {Object} firebaseUser - Firebase user object
   */
  async function login(firebaseUser) {
    if (userIsAuthenticated.value) {
      return
    }

    await setAuthToken(firebaseUser)

    // IMPORTANT: must set the user data before fetching cart
    // to ensure the proper shopping zip is used, among other things
    const data = await $pegApi.Users.get()

    // something went wrong, abort
    if (!data.success) {
      return { error: true }
    }

    // no peg user found, attempt to create user
    if (!data.user?.id) {
      // set first/last name if available
      const { firstName, lastName } = LocalStorage.getItem('peg_userSignIn') || {}

      // IMPORTANT: first/last name must be null if not provided
      const data = await $pegApi.Users.create({
        email: firebaseUser.email,
        firstName: firstName || null,
        lastName: lastName || null,
      })

      if (!data.success) {
        return { error: true }
      }

      $logger.debug('NEW USER CREATED', data.user)
    }

    // massage certain user data
    Object.assign(user.value, {
      ...data.user,
      // clean phone
      phone: data.user?.phone ? data.user.phone.slice(-10) : null,
    })

    const sessionGeo = LocalStorage.getItem('peg_geo')
    const sessionZip = sessionGeo?.zip || user.value?.shoppingZip
    if (sessionZip) {
      // TODO: retain user's shopping zip if already set?
      shoppingZip.value = sessionZip
    } else {
      await setShoppingZipByIp()
      await updateUser({ shoppingZip: shoppingZip.value.toString() })
    }

    // fetch user's cart if not on cart page (cart store will handle fetching on cart page)
    if (!isCartPage.value) {
      cartStore.fetchCart()
    }

    getWatchlist()

    // inject some firebase user data into peg user
    user.value.emailVerified = firebaseUser.emailVerified

    // update email address if changed
    if (user.value.email !== firebaseUser.email) {
      updateEmail(firebaseUser, user.value.email)
    }

    // update display name if changed
    if (user.value?.firstName && user.value?.lastName) {
      const displayName = `${user.value.firstName} ${user.value.lastName}`
      if (firebaseUser.displayName !== displayName) {
        updateProfile(firebaseUser, { displayName })
      }
    }

    // ms clarity - track user session
    if (window.clarity) {
      $logger.debug('CLARITY ID', user.value.id)
      window.clarity('identify', user.value.id)
    }

    // klaviyo - track user session
    $analytics.klaviyo.identify({
      email: user.value.email,
      $first_name: user.value.firstName,
      $last_name: user.value.lastName,
    })

    // rollbar - track user session
    $rollbar.configure({
      payload: {
        person: {
          id: user.value.id,
        },
      },
    })

    userIsAuthenticated.value = true
    authIsInitialized.value = true
  }

  /**
   * Logout user, clear user data and auth token
   */
  async function logout() {
    userIsAuthenticated.value = false
    user.value = {}
    setAuthToken(null)

    // setShoppingZipByIp()

    authIsInitialized.value = true
  }

  /**
   * Wait for auth initialization to complete
   * @returns {Promise} - Promise that resolves when auth is initialized
   */
  async function awaitAuthInit() {
    if (!authIsInitialized.value) {
      Loading.show()
      return new Promise(resolve => {
        const interval = setInterval(() => {
          if (authIsInitialized.value) {
            Loading.hide()
            clearInterval(interval)
            resolve()
          }
        }, 100)
      })
    }

    return Promise.resolve()
  }

  /**
   * Update user data in PEG API
   * @param {Object} data - User data to update
   * @returns {Promise} - Promise with success status
   */
  async function updateUser(data) {
    Loading.show()
    // map billing and shipping address fields
    if (data.billingAddress?.address1) {
      data.billingAddress1 = data.billingAddress.address1 || null
      data.billingAddress2 = data.billingAddress.address2 || null
      data.billingCity = data.billingAddress.city || null
      data.billingState = data.billingAddress.state || null
      data.billingZip = data.billingAddress.zip || null
      delete data.billingAddress
    }
    if (data.shippingAddress?.address1) {
      // override first, last, and phone with shipping address values
      data.firstName = data.shippingAddress?.firstName || data.firstName
      data.lastName = data.shippingAddress?.lastName || data.lastName
      data.phone = data.shippingAddress?.phone || data.phone

      data.shippingAddress1 = data.shippingAddress.address1 || null
      data.shippingAddress2 = data.shippingAddress.address2 || null
      data.shippingCity = data.shippingAddress.city || null
      data.shippingState = data.shippingAddress.state || null
      data.shippingZip = data.shippingAddress.zip || null
      delete data.shippingAddress
    }

    // allow partial updates
    const dataToUpdate = { ...user.value, ...data }
    // remove unused fields
    delete dataToUpdate['3rdPAuthUserId']
    /* eslint-disable no-unused-vars */
    const {
      id,
      status,
      emailVerified,
      defaultPaymentTokenDidFail,
      inPenaltyBox,
      penaltyBoxCount,
      dateModified,
      dateCreated,
      cdpUserId,
      phoneVerified,
      ...rest
    } = dataToUpdate
    /* eslint-enable no-unused-vars */
    const { success, user: updatedUser } = await $pegApi.Users.update(rest)
    Loading.hide()

    if (success) {
      const phone = updatedUser.phone ? updatedUser.phone.slice(-10) : null
      user.value = { ...user.value, ...updatedUser, phone }

      return Promise.resolve({ success: true })
    } else {
      return Promise.resolve({ success: false })
    }
  }

  function acceptTerms() {
    return updateUser({ termsAccepted: true })
  }

  function setDefaultPayment(token) {
    return updateUser({ paymentSaved: true, defaultPaymentToken: token })
  }

  function setShoppingZip(zip) {
    shoppingZip.value = zip

    if (userIsAuthenticated.value) {
      return updateUser({ shoppingZip: zip.toString() })
    }

    return { success: true }
  }

  async function setShoppingZipByIp() {
    let geoData = {}

    // Drey updated this to always use the proxy instead of local fetch to align with native app
    // TODO: Should we keep this or revert to local fetch?
    // if ($q.platform.is.nativeMobile) {
    // this points the request to the legacy rrc-proxy endpoint on native mobile
    const resp = await $proxy.fetch('/geolocation')
    geoData = resp?.data || {}
    // } else {
    //   geoData = await $fetch.fetch('/v1/geolocation')
    // }

    const { postalCode: zip, country, subdivision, success, ...rest } = geoData || {}

    if (success) {
      LocalStorage.setItem('peg_geo', { ...rest, zip, country: country?.code, state: subdivision?.code })
    }

    // fallback to default zip
    setShoppingZip(zip || '68144')
  }

  function getWatchlist() {
    // fetch user's userW
    return $pegApi.Users.getWatchlistItems().then(({ watchlistItems = [], meta = {} }) => {
      userWatchlist.items = watchlistItems
      userWatchlist.totalCount = meta.totalCount || 0
    })
  }

  /**
   * Add an auction item to the user's watchlist
   */
  function addWatchlistItem(auctionItemId, options = {}) {
    const { notify = true } = options

    // check if item is already in watchlist
    if (userWatchlist.items.some(item => item.auctionItemId === auctionItemId)) {
      return Promise.resolve({ success: true })
    }

    return $pegApi.Users.addWatchlistItem(auctionItemId).then(resp => {
      if (resp.success) {
        // re-fetch watchlist
        getWatchlist()

        if (notify) {
          $analytics.klaviyo.event('Watchlist Item Added', { item_id: auctionItemId })
        }
      }

      if (notify) {
        Notify.create({
          type: resp.success ? 'success' : 'error',
          message: resp.success ? 'Item added to watchlist' : 'Failed to add item to watchlist',
        })
      }

      return resp
    })
  }

  /**
   * Remove an auction item from the user's watchlist
   */
  function removeWatchlistItem(item, options = {}) {
    const { notify = true } = options
    const { id, watchlistItemId } = item

    return $pegApi.Users.removeWatchlistItem(watchlistItemId).then(resp => {
      if (resp.success) {
        userWatchlist.items = userWatchlist.items.filter(item => item.id !== watchlistItemId)
        userWatchlist.totalCount--

        if (notify) {
          $analytics.klaviyo.event('Watchlist Item Removed', { item_id: id })
        }
      }

      if (notify) {
        Notify.create({
          type: resp.success ? 'success' : 'error',
          message: resp.success ? 'Item removed from watchlist' : 'Failed to remove item from watchlist',
        })
      }

      return resp
    })
  }

  async function checkLocationPermission() {
    const { location } = await Geolocation.checkPermissions()

    hasGrantedLocation.value = location === 'granted'
  }

  /**
   * Get the user's current location
   */
  async function getLocation() {
    setTimeout(() => {
      locationLoadingText.value = 'Honing In'

      setTimeout(() => {
        locationLoadingText.value = 'Verifying'
      }, 3000)
    }, 6000)
    const newPosition = await Geolocation.getCurrentPosition({
      enableHighAccuracy: true,
    })

    const newLocation = {
      latitude: newPosition.coords.latitude,
      longitude: newPosition.coords.longitude,
      accuracy: newPosition.coords.accuracy,
    }
    location.value = { ...newLocation }

    // re-check permission to set flag
    await checkLocationPermission()

    return newPosition
  }

  function resetLocation() {
    location.value = { accuracy: null, latitude: null, longitude: null }
  }

  return {
    // state
    sessionKey,
    authIsInitialized,
    userIsAuthenticated,
    user,
    sessionAuthToken,
    shoppingZip,
    userWatchlist,
    location,
    locationLoadingText,
    hasGrantedLocation,
    // computed
    baseProfile,
    billingAddress,
    shippingAddress,
    billingAddressCompleted,
    shippingAddressCompleted,
    accountEmailVerified,
    accountPaymentSaved,
    accountProfileCompleted,
    accountTermsAccepted,
    accountBillingCompleted,
    accountSetupCompleted,
    emailSmsConsent,
    hasVerifiedPhone,
    hasValidLocation,
    userIsInPenaltyBox,
    userIsInternal,
    sessionIsLockedDown,
    // actions
    acceptTerms,
    addWatchlistItem,
    awaitAuthInit,
    checkLocationPermission,
    getLocation,
    login,
    logout,
    refreshSessionKey,
    removeWatchlistItem,
    resetLocation,
    setAuthToken,
    setDefaultPayment,
    setShoppingZip,
    updateUser,
  }
})
