import { $fetch } from 'boot/fetch'

const ENDPOINT = '/returns'

export default {
  async getBulk(params = {}) {
    if (!params.limit) {
      params.limit = 10
      params.offset = 0
    }
    params.sortBy = params.sortBy || 'dateCreated:DESC'

    return $fetch.peg(ENDPOINT, { params })
  },
  async getSingle(id) {
    return $fetch.peg(`${ENDPOINT}/${id}`)
  },
}
