// import { $logger } from 'src/boot/logger'

export const useNavigationStore = defineStore('navigation', () => {
  const history = ref([])
  const lastPopped = ref(null)

  const hasHistory = computed(() => history.value.length > 1)
  const currentRoute = computed(() => {
    return history.value.slice(-1)[0]
  })
  const previousRoute = computed(() => {
    return history.value.length > 1 ? history.value.slice(-2)[0] : null
  })

  function clearHistory() {
    // $logger.debug('clearHistory')
    history.value = []
  }

  function appendHistory(newRoute) {
    // $logger.debug('appendHistory', newRoute)
    history.value.push(newRoute)
    // $logger.debug('history', history.value)
  }

  function popHistory() {
    lastPopped.value = history.value.pop()
    // $logger.debug('popHistory', lastPopped.value)
    // $logger.debug('history', history.value)
  }

  return {
    // STATE
    hasHistory,
    history,
    lastPopped,
    // GETTERS
    currentRoute,
    previousRoute,
    // ACTIONS
    appendHistory,
    clearHistory,
    popHistory,
  }
})
