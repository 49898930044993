<!-- Environment indicator for test builds -->
<template>
  <div
    v-if="$q.platform.is.nativeMobile || isTestBuild"
    class="safe-area-top"
    :class="{ 'bg-white': applyBackground }"
    style="z-index: 1000000"
  >
    <div v-if="isTestBuild" class="test-banner text-white flex-center bg-teal-4">
      <div v-for="(x, idx) in Array(64)" :key="x" class="flex-center">
        <q-icon v-if="idx > 0" class="q-mx-md" name="far fa-exclamation-triangle" /><span class="text-weight-bold">TEST</span>
      </div>
    </div>
  </div>
</template>

<script setup>
const $q = useQuasar()
const route = useRoute()

const isTestBuild = computed(() => {
  const appId = import.meta.env.VITE_APP_ID || ''
  return appId.includes('.test') || window.location.host.includes('test')
})

const applyBackground = computed(() => {
  return route.path !== '/' && !route.path.startsWith('/app/splash')
})
</script>

<style lang="scss" scoped>
.safe-area-top {
  height: $safe-area-inset-top;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  font-size: 8px;
  line-height: 0.7rem;
}
</style>
