import { defineBoot } from '#q-app/wrappers'
import { $logger } from 'boot/logger'
import { $rollbar } from 'boot/rollbar'
import { LocalStorage } from 'quasar'

const $analytics = {}
export default defineBoot(({ router, store }) => {
  const sessionStore = useSessionStore(store)

  // initialize google analytics based on environment
  window.gtag('config', import.meta.env.VITE_GOOGLE_ANALYTICS_ID, {
    send_page_view: false,
  })

  // track page views
  // 1s delay to allow meta to be updated via useMeta() composable (see usePageMeta.js)
  router.afterEach(to => {
    setTimeout(() => {
      $analytics.pageView(to)
    }, 1000)
  })

  /* GOOGLE */
  const google = {
    /**
     * Initialize Google Reviews
     * @param {string} orderId The order id
     * @param {string} email The email attached to the order
     * @param {string} edd The estimated delivery date
     * @returns {void}
     * @see https://support.google.com/merchants/answer/7519329?hl=en&ref_topic=7105160&sjid=4756415241457901103-NA
     */
    // initReviews(orderId, email, edd) {
    //   const { google = {} } = ctx.store.state.siteSettings.analytics

    //   if (!google?.enabled || !google?.reviews?.enabled || !google.merchantId || !orderId || !email || !edd) {
    //     $logger.debug('GOOGLE REVIEWS NOT AVAILABLE', google, { orderId, email, edd })
    //     return
    //   }

    //   // inject google reviews script tag in header
    //   const script = document.createElement('script')
    //   script.src = 'https://apis.google.com/js/platform.js?onload=renderOptIn'
    //   script.async = true
    //   document.head.appendChild(script)

    //   // render the optin popup
    //   window.renderOptIn = function () {
    //     window.gapi.load('surveyoptin', function () {
    //       window.gapi.surveyoptin.render({
    //         // REQUIRED FIELDS
    //         merchant_id: google.merchantId,
    //         order_id: orderId,
    //         email,
    //         delivery_country: 'US',
    //         estimated_delivery_date: edd,

    //         // OPTIONAL FIELDS
    //         // products: [{'gtin':'GTIN1'}, {'gtin':'GTIN2'}]
    //       })
    //     })
    //   }

    //   $logger.debug('Google Reviews Initialized')

    //   // initialize ratings badge if enabled
    //   if (google.reviews.enableBadge) {
    //     // inject badge script tag in header
    //     const script = document.createElement('script')
    //     script.src = 'https://apis.google.com/js/platform.js?onload=renderBadge'
    //     script.async = true
    //     document.head.appendChild(script)

    //     window.renderBadge = function () {
    //       const ratingBadgeContainer = document.createElement('div')
    //       document.body.appendChild(ratingBadgeContainer)
    //       window.gapi.load('ratingbadge', function () {
    //         window.gapi.ratingbadge.render(ratingBadgeContainer, {
    //           // REQUIRED
    //           merchant_id: google.merchantId,
    //           // OPTIONAL
    //           // position: 'POSITION',
    //         })
    //       })
    //     }

    //     $logger.debug('Google Reviews Badge Initialized')
    //   }
    // },
    /**
     * Track a generic event
     * @param {string} eventType The type of event to track
     * @param {object} eventProps The event properties to track
     * @param {object} userData The user data to track
     * @returns {void}
     */
    trackEvent(eventType, eventProps, userData) {
      try {
        // set user properties
        const userLocale = LocalStorage.getItem('peg_geo')
        const userId = sessionStore?.user?.id

        if (userId) {
          const userProps = {
            user_id: userId,
            region_name: userLocale?.state || 'undefined',
          }
          window.gtag('set', 'user_properties', userProps)
        }

        if (userData) {
          window.gtag('set', 'user_data', userData)
        }

        // track event
        window.gtag('event', eventType, { ...eventProps, page: window.location.pathname })
      } catch (error) {
        const msg = 'Analytics Error: Google: trackEvent()'
        $logger.error(msg, error)
        $rollbar.error(msg, error)
      }
    },
  }

  /* KLAVIYO */
  $analytics.klaviyo = {
    identify(args) {
      if (!window.klaviyo) {
        $rollbar.info('Klaviyo not initialized')
        return
      }

      try {
        window.klaviyo.identify(args)
        // $logger.debug('KLAVIYO ID', args)
      } catch (error) {
        const msg = 'Analytics Error: Klaviyo: identify()'

        $logger.error(msg, error)
        $rollbar.error(msg, error)
      }
    },
    /**
     * Track a generic event
     * @param {string} eventName The name of event to track
     * @param {object} eventProps The event properties to track
     * @param {object} userData The user data to track
     * @returns {void}
     */
    event(eventName, eventProps, userData = {}) {
      try {
        // set user properties
        // const userId = sessionStore?.user?.id

        // if (userId) {
        //   const userProps = {
        //     $id: userId,
        //   }
        //   window.klaviyo.push(['identify', userProps])
        // }

        // track event
        window.klaviyo.push(['track', eventName, { ...eventProps, ...userData }])
        $logger.debug('KLAVIYO EVENT', eventName, { ...eventProps, ...userData })
      } catch (error) {
        const msg = 'Analytics Error: Klaviyo: trackEvent()'
        $logger.error(msg, error)
        $rollbar.error(msg, error)
      }
    },
  }

  /**
   * Handle for an array of event names being passed in
   * @param {string,array} eventName Name of the event or array of names
   * @returns {string} Concatenated string of event names
   */
  const parseEventName = eventName => {
    // handle for eventName as an array of contexts
    if (Array.isArray(eventName)) {
      eventName = eventName.filter(Boolean).join(' > ')
    }

    return eventName
  }

  /**
   * Agnostic click event tracking
   * @param {string} eventType - The type of event
   * @param {string} eventName - The name of the event
   * @param {object} eventProps - Additional event properties
   * @param {object} userProps - Additional user properties
   */
  $analytics.click = function (eventName, eventProps = {}, userProps = {}) {
    eventProps.name = parseEventName(eventName)

    // amplitude.trackEvent('click', eventProps, userProps)
    google.trackEvent('click', eventProps, userProps)
  }

  /**
   * Agnostic interaction event tracking
   */
  $analytics.interaction = (eventName, eventProps = {}, userProps = {}) => {
    eventProps.name = parseEventName(eventName)

    // amplitude.trackEvent('interaction', eventProps, userProps)
    google.trackEvent('interaction', eventProps, userProps)
  }

  /**
   * Agnostic transaction event tracking
   */
  $analytics.transaction = (eventName, eventProps = {}, userProps = {}) => {
    eventProps.name = parseEventName(eventName)

    // amplitude.trackEvent('transaction', eventProps, userProps)
    google.trackEvent('transaction', eventProps, userProps)
  }

  /**
   * Agnostic page view tracking
   * @param {object} route The current route
   */
  $analytics.pageView = function (route) {
    route = route || router.currentRoute.value
    const { path } = route
    const params = {}
    if (route.params.tid || route.query.tid) {
      params.tid = route.params.tid || route.query.tid
    }
    if (route.query.q) {
      params.q = route.query.q.toLowerCase()
    }
    // ensure PdP quick views are tracked as product page views
    // if (hasProductQuickViewHash(route.hash)) {
    //   const hash = qs.parse(route.hash)
    //   path = `/product/${hash.pid}`
    // }
    // const regex = new RegExp(`https?://${window.location.host}`)
    // return `${window.location.pathname}${window.location.search}`
    const qs = new URLSearchParams(params).toString()
    const eventProps = {
      page: `${path}${qs.length ? `?${qs}` : ''}`,
      title: document.title,
      location: window.location.href, // .replace(regex, ''),
    }

    // amplitude.trackEvent('page_view', eventProps)
    google.trackEvent('page_view', eventProps)
  }
})

export { $analytics }
