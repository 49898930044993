/* eslint-disable */
/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.config file > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/

import iconSet from 'quasar/icon-set/fontawesome-v6-pro.js'



import {Cookies,Dialog,LocalStorage,Loading,Meta,Notify,SessionStorage} from 'quasar'



export default { config: {"brand":{"primary":"#ef4136","secondary":"#fef4e8","accent":"#0E9594","positive":"#6CAE75","info":"#7b94af"},"loading":{"backgroundColor":"white","spinnerColor":"grey-4"}},iconSet,plugins: {Cookies,Dialog,LocalStorage,Loading,Meta,Notify,SessionStorage} }

