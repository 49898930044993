import flagsmith from 'flagsmith'
import { $logger } from './logger'

// Initialize Flagsmith
flagsmith.init({
  environmentID: import.meta.env.VITE_FLAGSMITH_ENV_ID,
  onChange: (oldFlags, params) => {
    $logger.debug('FEATURE FLAGS CHANGED', oldFlags, params)
  },
})

$logger.debug('FEATURE FLAGS', flagsmith)

export const $flagsmith = flagsmith
