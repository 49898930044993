import { $fetch } from 'boot/fetch'

const ENDPOINT = '/checkout'

export default {
  /**
   * Create a new checkout instance
   * @param {Object} body - Checkout details
   * @returns {Promise} - Checkout details
   */
  async create(body) {
    return $fetch.peg(ENDPOINT, { method: 'POST', body })
  },
  /**
   * Update the checkout
   * @param {Object} body - Checkout details
   * @returns {Promise} - Checkout details
   */
  async update(body) {
    return $fetch.peg(ENDPOINT, { method: 'PUT', body })
  },
  /**
   * Complete the checkout, capture payment, and create order
   * @param {String} body.nonce - Payment nonce
   * @returns {Promise} - Checkout details
   */
  async complete(body) {
    return $fetch.peg(`${ENDPOINT}/complete`, { method: 'POST', body })
  },
}
