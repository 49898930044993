import { $fetch } from 'boot/fetch'

const endpoint = '/users/watchlistItems'

export default {
  add(auctionItemId) {
    return $fetch.peg(endpoint, {
      method: 'POST',
      body: { auctionItemId },
    })
  },
  getAll(params = {}) {
    params = { limit: 300, sortBy: 'purchaseExpireTime:ASC', ...params }
    return $fetch.peg(endpoint, { params })
  },
  remove(id) {
    return $fetch.peg(`${endpoint}/${id}`, {
      method: 'DELETE',
    })
  },
}
