import { $fetch } from 'boot/fetch'

const endpoint = '/returns/sessions'

export default {
  /**
   * @description start a return session
   * @function add
   * @returns {Promise} - { returnSession: { id }}
   */
  start() {
    return $fetch.peg(endpoint, {
      method: 'POST',
    })
  },
}
