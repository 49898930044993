import { $fetch } from 'boot/fetch'
import { Cookies } from 'quasar'

const ENDPOINT = '/retailers'

function buildAdminHeaders() {
  return { 'x-access-token': Cookies.get('peg_retailer_auth') }
}

export default {
  /**
   * @description Get all retailers
   * @function getAll
   * @param {Object} params
   * @param {String} [params.id] - The retailer id
   * @param {Boolean} [params.active] - get active retailers
   * @param {Integer} [params.limit=10]
   * @param {Integer} [params.offset=0]
   * @returns {Promise} - [{ domain, id, name, shopId }]
   */
  async getAll(params = {}) {
    return $fetch.peg(ENDPOINT, {
      params,
    })
  },

  /**
   * @description Get a retailer by ID
   * @function getSingle
   * @param {String} retailerId - The retailer ID
   * @returns {Promise}
   */
  async getSingle(retailerId) {
    return $fetch.peg(`${ENDPOINT}/${retailerId}`, { headers: buildAdminHeaders() })
  },

  /**
   * @description Update a single retailer
   * @function updateSingle
   * @param {String} retailerId - The retailer ID
   * @param {Object} body - The updated retailer object
   * @returns {Promise}
   */
  async updateSingle(retailerId, body) {
    return $fetch.peg(`${ENDPOINT}/${retailerId}`, {
      method: 'PUT',
      headers: buildAdminHeaders(),
      body,
    })
  },

  /**
   * @description Get a retailer's order by order number and customer email address
   * @function orderLookup
   * @param {String} customerEmail
   * @param {String} orderNumber
   * @param {Boolean} [raw=false] - true to get full response
   * @returns {Promise}
   */
  async orderLookup(customerEmail, orderNumber) {
    return $fetch.peg(`${ENDPOINT}/orderLookup`, {
      params: { customerEmail, orderNumber },
    })
  },

  /**
   * @description Get a retailer's order by order number
   * @function getRetailerOrder
   * @param {String} retailerId
   * @param {String} orderNumber - the customer facing order number
   * @param {Object} params
   * @param {Number} [params.latitude]
   * @param {Number} [params.longitude]
   * @param {Boolean} [params.raw=false] - true to get full response
   * @returns {Promise}
   */
  getRetailerOrder(retailerId, orderNumber, { latitude = null, longitude = null, raw = false }) {
    let params = {}
    if (latitude) params.latitude = latitude
    if (longitude) params.longitude = longitude

    return $fetch.peg(`${ENDPOINT}/${retailerId}/orders/${orderNumber}`, {
      raw,
      params: { ...params },
    })
  },

  /**
   * @description Submit a return request
   * @function submitReturn
   * @param {String} retailerId
   * @param {String} orderNumber - the customer facing order number
   * @param {Object} body
   * @returns {Promise}
   */
  submitReturn(retailerId, orderNumber, body) {
    return $fetch.peg(`${ENDPOINT}/${retailerId}/orders/${orderNumber}/return`, {
      method: 'POST',
      body,
    })
  },

  /**
   * @description Verify an order email address with OTP if the email does not match the logged in user email
   * @function verifyOtp
   * @param {String} retailerId
   * @param {String} orderNumber - the customer facing order number
   * @param {String} otp
   * @returns {Promise}
   */
  verifyOtp(retailerId, orderNumber, otp) {
    return $fetch.peg(`${ENDPOINT}/${retailerId}/orders/${orderNumber}/verifyOTP`, {
      method: 'PUT',
      raw: true,
      body: { otp: otp.toString().toUpperCase() },
    })
  },
}
