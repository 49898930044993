export const RETURN_ITEM_PROCESS_DATA = {
  // as defined in app_return_flows
  appReturnFlowName: 'STANDARD',
  // boxes
  numberOfBoxes: 1,
  // [{ type, label, value }]
  returnReasons: [],
  preferenceDetails: '',
  defectiveDetails: '',
  // { description, images: [{ id, image: base64 }] }
  damageDetails: '',
  // Yes/No leading questions
  // [{ slug, answer }]
  returnQuestions: [],
  // DAMAGE, BOX, and PRODUCT images
  // [{ id, mediaType, image: base64, description }],
  images: [],
  // Damage details
  // this will be pushed into images at the submit
  // [{ description, images: [{ image: base64 }] }]
  //damageItems: [],
}
