import { $fetch } from 'boot/fetch'

const endpoint = '/returns/sessions/media'

export default {
  /**
   * @description Add a single media item to a return session
   * @function add
   * @param {Object} params
   * @param {String} params.mediaType - 'BOX' | 'PRODUCT' | 'DAMAGE'
   * @param {Base64} params.image
   * @param {String} params.description
   * @returns {Promise} - { returnSessionMedia: { id, url, mediaType, description }}
   */
  add({ mediaType, image, description }) {
    return $fetch.peg(endpoint, {
      method: 'POST',
      body: {
        mediaType,
        image,
        description,
      },
    })
  },
}
