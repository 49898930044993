import { useSessionStore } from 'stores/session.store'
import { $logger } from 'boot/logger'
import { $rollbar } from 'boot/rollbar'

/**
 * Standardized fetch wrapper
 * @param {String} url - URL to fetch
 * @param {Object} options - Fetch options
 * @returns {Promise}
 */
function doFetch(url, options = {}) {
  let { method = 'GET', headers = {}, body = null, params = null, raw = false } = options

  headers = {
    ...(headers || {}),
    'Content-Type': 'application/json',
  }

  if (body) {
    body = JSON.stringify(body)
  }

  if (params) {
    // stringify params object
    const searchParams = new URLSearchParams()
    for (const key in params) {
      searchParams.append(key, params[key])
    }
    // append to endpoint
    url += `?${searchParams.toString()}`
  }

  return fetch(url, { method, headers, body })
    .then(res => res.json())
    .then(res => {
      if (res.statusCode >= 400) {
        throw res
      }

      // optionally return full response
      if (raw) {
        return { ...res, success: true }
      }

      // only return data object
      return { ...res.data, success: true }
    })
    .catch(err => {
      $logger.error('FETCH ERROR', url, err)
      $rollbar.error('FETCH ERROR', { url, err })

      return { ...err, success: false }
    })
}

/**
 * Fetch data from the PEG API
 * @param {String} endpoint - API endpoint
 * @param {Object} options - Fetch options
 * @returns {Promise} - API response
 */
function peg(endpoint, options = {}) {
  const url = `${import.meta.env.VITE_PEG_API_URL}${endpoint}`
  const sessionStore = useSessionStore()
  const customerReturnStore = useCustomerReturnStore()

  options.headers = {
    ...(options.headers || {}),
    'x-app-id': import.meta.env.VITE_PEG_API_APP_ID,
    'x-id-token': sessionStore.sessionAuthToken,
  }

  if (customerReturnStore.returnSessionId) {
    options.headers['x-return-session-id'] = customerReturnStore.returnSessionId
  }

  return doFetch(url, options)
}

export const $fetch = {
  fetch: doFetch,
  peg,
}
