import { date } from 'quasar'
import { cloneDeep } from 'lodash-es'
import { formatTimer } from 'src/utils/time.util'
import { $flagsmith } from 'boot/flagsmith'

/**
 * Parse product images
 * @param {Object[]} images - Array of images
 * @returns {Object[]} - Array of images
 */
export function parseImages(images = []) {
  const imagePriority = ['KNOCKOUT', 'PRODUCT', 'BOX', 'DAMAGE']

  if (!images) {
    return []
  }

  return (
    images
      .map(image => {
        // force https
        image.url = image.url
          .replace(/(http|https)?:\/\//, 'https://')
          .replace('preprodimages.rushmarket.com', 'preprodrushimages.blob.core.windows.net')

        return image
      })
      // ensure knockout images are first, followed by product images
      .sort((a, b) => {
        const aIndex = imagePriority.indexOf(a.mediaType) + 1 || imagePriority.length
        const bIndex = imagePriority.indexOf(b.mediaType) + 1 || imagePriority.length

        return aIndex - bIndex
      })
  )
}

/**
 * Parse product item
 * @param {Object} item - Auction item
 * @returns {Object} - Parsed auction item
 */
export function parseItem(item) {
  const data = cloneDeep(item)

  // skip parsing images if already parsed
  if (!('endTimer' in data)) {
    data.images = parseImages(data.images)
  }

  // totalExpireTime comes from the cart when a `WON` item exists
  // each item in the cart can have a different purchaseExpireTime and totalExpireTime
  data.secondsToEnd = date.getDateDiff(new Date(data.totalExpireTime || data.purchaseExpireTime || null), new Date(), 'seconds')

  data.secondsToStart = date.getDateDiff(new Date(data.auctionStart), new Date(), 'seconds')

  // start/end timers
  data.startTimer = data.secondsToStart > -1 ? formatTimer(data.secondsToStart) : null
  data.endTimer = data.secondsToStart < 1 && data.secondsToEnd > -1 ? formatTimer(data.secondsToEnd) : null

  // status
  data.pending = data.status === 'PRE-AUCTION' || data.secondsToStart > 0
  data.auction = ['AUCTION', 'UP_FOR_GRABS'].includes(data.status)
  data.sold = ['SOLD', 'WON', 'UNPAID'].includes(data.status) || (data.auction && data.secondsToEnd <= 0 && data.bidCount > 0)
  data.won = ['WON', 'UNPAID'].includes(data.status)
  data.unpaid = ['UNPAID'].includes(data.status)
  data.expired = ['EXPIRED'].includes(data.status) || (data.auction && !data.sold && data.secondsToEnd <= 0)
  data.relisted = ['RELISTED'].includes(data.status)
  data.liveAuction = ['AUCTION'].includes(data.status) && !data.pending && !data.sold && !data.expired
  data.upForGrabs = ['UP_FOR_GRABS'].includes(data.status) && !data.expired && !data.sold
  data.active = data.liveAuction || data.upForGrabs

  // current bid is either the user's max bid or the current bid
  // if current user is the max bid holder, the current user's next bid must exceed their current max bid
  const currentBid = data.userMaxBid > data.currentBid ? data.userMaxBid : data.currentBid
  data.minBid = currentBid < data.startingPrice ? data.startingPrice : currentBid + 1
  data.buyItNow = Boolean(data.liveAuction && data.buyItNowPrice > data.minBid && !data.expired && !data.sold)
  data.originalPrice = data[$flagsmith.getValue('original_price_prop')] || data.retailPrice
  data.purchasePrice = data.purchasePrice || (data.buyItNow ? data.buyItNowPrice : data.currentBid)

  const itemId = data.relistedId || data.auctionItemId || data.itemId || data.id
  data.pdpUrl = itemId ? `/shop/product/${itemId}/` : null

  return data
}
