import Typesense from 'typesense'

export const $typesense = new Typesense.Client({
  nodes: [
    {
      host: import.meta.env.VITE_TYPESENSE_API_HOST, // For Typesense Cloud use xxx.a1.typesense.net
      port: 443, // For Typesense Cloud use 443
      protocol: 'https', // For Typesense Cloud use https
    },
  ],
  apiKey: import.meta.env.VITE_TYPESENSE_API_KEY,
  connectionTimeoutSeconds: 2,
})
