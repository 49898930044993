<template>
  <AppSafeZoneTop />
  <router-view :key="$q.screen.name" />
</template>

<script setup>
const $q = useQuasar()

defineOptions({
  name: 'App',
})
</script>
