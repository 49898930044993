import { $fetch } from 'boot/fetch'
import { Platform } from 'quasar'

// const ENDPOINT = '/auth'

export default {
  async sendEmailSignInLink({ email, path }) {
    return $fetch.peg('/auth/sendSignInLinkToEmail', {
      method: 'POST',
      headers: { 'x-platform': Platform.is.nativeMobile ? 'native' : 'web' },
      body: { emailAddress: email, path },
    })
  },
}
