import AppReturnFlow from 'src/api/peg/appReturnFlow'
import AuctionItems from 'src/api/peg/auctionItems'
import AuctionItemBids from 'src/api/peg/auctionItemBids'
import Auth from 'src/api/peg/auth'
import Cart from 'src/api/peg/cart'
import Categories from 'src/api/peg/categories'
import Checkout from 'src/api/peg/checkout'
import CityState from 'src/api/peg/cityState'
import Orders from 'src/api/peg/orders'
import PaymentGateway from 'src/api/peg/paymentGateway'
import Retailers from 'src/api/peg/retailers'
import Returns from 'src/api/peg/returns'
import ReturnSessions from 'src/api/peg/returnSessions'
import ReturnSessionMedia from 'src/api/peg/returnSessionMedia'
import Search from 'src/api/peg/search'
import Users from 'src/api/peg/users'

export const $pegApi = {
  AppReturnFlow,
  AuctionItems,
  AuctionItemBids,
  Auth,
  Cart,
  Categories,
  Checkout,
  CityState,
  Orders,
  PaymentGateway,
  Retailers,
  Returns,
  ReturnSessions,
  ReturnSessionMedia,
  Search,
  Users,
}
