import { $analytics } from 'boot/analytics'
import { $pegApi } from 'boot/peg-api'
import { Notify } from 'quasar'
import { parseItem } from 'src/utils/product.util'
import { PICKUP, DELIVERY } from 'src/constants/fulfillment.const'

export const useCartStore = defineStore('cart', () => {
  const $q = useQuasar()
  const sessionStore = useSessionStore()

  const cartItems = ref([])
  const cartIsFetching = ref(true)
  const cartIsUpdating = ref(false)
  const selectedCheckoutItems = ref($q.localStorage.getItem('peg_checkout_items') || [])

  watchEffect(() => {
    if (!cartIsFetching.value && selectedCheckoutItems.value.length) {
      // ensure all selected items still exist in cart and update local storage
      selectedCheckoutItems.value = selectedCheckoutItems.value.filter(sid =>
        cartItems.value.some(item => item.auctionItemId === sid)
      )
      $q.localStorage.setItem('peg_checkout_items', selectedCheckoutItems.value)
    }
  })

  const totalQty = computed(() => cartItems.value.length)

  // group cart items by status
  const itemGroups = computed(() => {
    const groups = []
    const groupTypes = cartItems.value
      // sort by expiry time
      .sort((a, b) => new Date(a.purchaseExpireTime).getTime() - new Date(b.purchaseExpireTime).getTime())
      // group into upForGrabs and liveAuction
      .reduce((acc, item) => {
        let key = ''

        switch (item.status) {
          case 'EXPIRED':
            key = 'expired'
            break

          case 'WON':
          case 'UNPAID':
            key = 'won'
            break

          default:
            key = 'cart'
            break
        }

        if (!acc[key]) {
          acc[key] = []
        }

        acc[key].push(item)

        return acc
      }, {})

    // won items come first
    if ('won' in groupTypes) {
      groups.push(groupTypes.won)
    }
    if ('cart' in groupTypes) {
      groups.push(groupTypes.cart)
    }
    if ('expired' in groupTypes) {
      groups.push(groupTypes.expired)
    }

    return groups
  })

  /* ---- CART CHECKOUT SUMMARY ---- */
  const checkoutItems = computed(() => {
    return cartItems.value.filter(item => selectedCheckoutItems.value.includes(item.auctionItemId))
  })
  const totalLineItemPrice = computed(() => {
    return checkoutItems.value.reduce((acc, item) => acc + item.purchasePrice, 0)
  })
  const totalShipping = computed(() => {
    return checkoutItems.value.reduce((acc, item) => {
      acc += item.isDeliveryFulfillment ? item.fulfillmentOptions?.delivery?.cost || 0 : 0
      return acc
    }, 0)
  })
  const totalTax = computed(() => {
    return checkoutItems.value.reduce((acc, item) => {
      acc += item?.estimatedTax || 0
      return acc
    }, 0)
  })
  const totalPrice = computed(() => {
    return totalLineItemPrice.value + totalTax.value + totalShipping.value
  })
  const hasDeliveryFulfillment = computed(() => checkoutItems.value.some(item => item.isDeliveryFulfillment))
  const hasPickupFulfillment = computed(() => checkoutItems.value.some(item => item.isPickupFulfillment))

  /**
   * Parse cart items to expected format
   * @param {Array} items - Cart items
   * @returns {void}
   */
  function parseCartItems(items) {
    cartItems.value = items.map(item => {
      item = {
        ...item,
        ...item.auctionItem, // include auctionItem data at top level
        id: item.id, // ensure cart item id persists given it would be overwritten by auctionItem.id
        isDeliveryFulfillment: item.fulfillmentMethod === DELIVERY,
        isPickupFulfillment: item.fulfillmentMethod === PICKUP,
      }
      delete item.auctionItem

      return parseItem(item)
    })
  }
  /**
   * Fetch active cart items
   */
  async function fetchCart() {
    cartIsFetching.value = true
    const { cart } = await $pegApi.Cart.get({ zip: sessionStore.shoppingZip })

    if (!cart) {
      cartIsFetching.value = false

      Notify.create({
        type: 'error',
        message: 'An error occurred while fetching your cart, please try again.',
      })
      return
    }

    // parse cart items
    parseCartItems(cart.items)

    cartIsFetching.value = false
  }

  /**
   * Add the current item to the user's cart
   */
  async function addItem(auctionItemId, options = {}) {
    cartIsUpdating.value = true

    const { fulfillmentMethod = PICKUP } = options
    const { success, cart } = await $pegApi.Cart.addItem({ auctionItemId, fulfillmentMethod })

    if (success) {
      $analytics.klaviyo.event('Add to Cart', { item_id: auctionItemId })

      // update cart items
      parseCartItems(cart.items)

      Notify.create({
        type: 'success',
        message: 'Item added to cart',
      })
    } else {
      Notify.create({
        type: 'error',
        message: 'Something went wrong, please try again.',
      })
    }

    cartIsUpdating.value = false

    return { success }
  }

  /**
   * Remove an item from the cart
   */
  async function removeItem(itemId) {
    cartIsUpdating.value = true

    const { success, cart } = await $pegApi.Cart.remove(itemId)

    if (success) {
      $analytics.klaviyo.event('Remove from Cart', { item_id: itemId })

      // update cart items
      parseCartItems(cart.items)

      Notify.create({
        type: 'success',
        message: 'Item removed from cart',
      })
    } else {
      Notify.create({
        type: 'error',
        message: 'Could not remove item from cart',
      })
    }

    cartIsUpdating.value = false

    return { success }
  }

  /**
   * Add an item to the checkout
   */
  function addCheckoutItem(item) {
    // if item is not already selected, add it
    if (!selectedCheckoutItems.value.some(sid => sid === item.auctionItemId)) {
      selectedCheckoutItems.value.push(item.auctionItemId)
    }
  }

  /**
   * Remove an item from the checkout
   */
  async function removeCheckoutItem(itemId) {
    selectedCheckoutItems.value = selectedCheckoutItems.value.filter(sid => sid !== itemId)
  }

  return {
    // STATE
    cartItems,
    cartIsFetching,
    cartIsUpdating,
    selectedCheckoutItems,
    // GETTERS
    itemGroups,
    totalQty,
    totalLineItemPrice,
    totalShipping,
    totalTax,
    totalPrice,
    hasDeliveryFulfillment,
    hasPickupFulfillment,
    // ACTIONS
    addItem,
    fetchCart,
    removeItem,
    addCheckoutItem,
    removeCheckoutItem,
  }
})
