import { $fetch } from 'boot/fetch'

const ENDPOINT = '/categories'

export default {
  getAll(params = {}) {
    return $fetch.peg(ENDPOINT, {
      params: {
        status: 'AUCTION',
        // includes: 'productCount',
        // excludeZeroProducts: true,
        ...params,
      },
    })
  },
  getParents() {
    return this.getAll({ groupByParent: true })
  },
  getChildren(parentId) {
    return this.getAll({ parentId })
  },
}
