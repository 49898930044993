import { $fetch } from 'boot/fetch'

const ENDPOINT = '/paymentGateway'

export default {
  getToken({ customerId }) {
    return $fetch.peg(`${ENDPOINT}/token`, { method: 'POST', body: { customerId } })
  },
  createCustomer(customerData) {
    return $fetch.peg(`${ENDPOINT}/customer`, { method: 'POST', body: customerData })
  },
  getCustomer() {
    return $fetch.peg(`${ENDPOINT}/customer`)
  },
  updateCustomer(customerData) {
    return $fetch.peg(`${ENDPOINT}/customer`, { method: 'PUT', body: customerData })
  },
  capturePayment({ paymentNonce }) {
    return $fetch.peg(`${ENDPOINT}/capture`, { method: 'POST', body: { nonce: paymentNonce } })
  },
}
