import { $logger } from 'boot/logger'
import { $rollbar } from 'boot/rollbar'
import { $typesense } from 'boot/typesense'
import { parseItem } from 'src/utils/product.util'

function mapFieldName(name) {
  switch (name) {
    // case 'cat1':
    //   return 'Category'
    case 'cat2':
      return 'Category'
    case 'cat3':
      return 'Category'
    case 'rating':
      return 'Quality Rating'
    default:
      return name
  }
}

function mapValueLabel(value) {
  switch (value) {
    case '0..2':
      return '0-2'
    case '2..4':
      return '2-4'
    case '4..5':
      return '4+'
    default:
      return value
  }
}

export default {
  /**
   * Fetch results meta to drive man nav drawer categories
   * @returns {Promise<Array>}
   */
  async getCategoryNav() {
    const params = {
      q: '*',
      limit: 1,
      offset: 0,
    }

    const resp = await this.search(params)

    return resp?.meta?.facets?.cat2?.values || []
  },
  /**
   * Fetch auction items from Typesense
   * @param {Object} params - Search parameters
   * @param {String} params.q - Search query
   * @param {Number} params.limit - Number of results to return
   * @param {Number} params.offset - Offset for pagination
   * @param {Array} params.filterBy - Array of filters to apply
   * @param {String} params.sortBy - Sort order
   * @returns {Promise<Object>}
   */
  async search(params = {}) {
    params = { ...params, filterBy: params.filterBy || [] }

    // default to only show AUCTION and UP_FOR_GRABS items if no status filter is provided
    if (!params.filterBy.some(filter => filter.includes('status:'))) {
      params.filterBy = ['status:[AUCTION,UP_FOR_GRABS]', ...params.filterBy].filter(Boolean)
    }
    $logger.debug('Search params:', params)

    function filterByIncludesFacet(facet) {
      return params.filterBy.some(f => f.startsWith(`${facet}:`))
    }

    function buildOtherFilters(facet) {
      return params.filterBy.filter(f => !f.startsWith(`${facet}:`)).join(' && ')
    }

    const commonParams = {
      collection: 'auction_items',
      q: params.q,
      query_by: 'title,description,notes,cat1,cat2,cat3,cat4,cat5,cat6,cat7,cat8',
      sort_by: params.sortBy,
    }

    const searchFacets = {
      // cat1: 'cat1(sort_by: _alpha:asc)',
      cat2: 'cat2(sort_by: _alpha:asc)',
      cat3: 'cat3(sort_by: _alpha:asc)',
      // rating: 'rating(0..2:[0,2], 2..4:[2,4], 4..5:[4, ])',
    }

    const searches = [
      // main search
      {
        facet_by: Object.values(searchFacets).join(','),
        limit: params.limit,
        offset: params.offset,
        filter_by: params.filterBy.join(' && '),
      },
    ]

    Object.keys(searchFacets).forEach(facet => {
      // include other facets if they exist as filters
      if (filterByIncludesFacet(facet)) {
        searches.push({
          facet_by: searchFacets[facet],
          filter_by: buildOtherFilters(facet),
          limit: 0,
        })
      }
    })

    const facets = {}

    return $typesense.multiSearch
      .perform({ searches }, commonParams)
      .then(async ({ results }) => {
        // main search results in first index
        // splice to remove main search results from facet_counts
        const { found: totalCount, hits } = results[0]
        $logger.debug('Typesense search results:', totalCount, hits)

        // parse auction items and format data
        const auctionItems = hits.map(({ document }) =>
          parseItem({
            ...document,
            auctionStart: new Date(document.auctionStart * 1000).toISOString(),
            auctionEnd: new Date(document.auctionEnd * 1000).toISOString(),
            purchaseExpireTime: document.purchaseExpireTime ? new Date(document.purchaseExpireTime * 1000).toISOString() : null,
            images: JSON.parse(document.images || '[]'),
          })
        )

        // parse results and combine facet values and counts
        results.forEach(({ facet_counts }) => {
          facet_counts.forEach(async facet => {
            // check for matching facet query
            const facetQuery = (results || []).reduce((acc, { facet_counts }) => {
              acc = facet_counts.find(({ field_name }) => field_name === facet.field_name)
              return acc
            }, {})

            // use facet query values if available, otherwise use main search values
            let values = facetQuery?.counts || facet.counts

            if (facet.field_name === 'rating') {
              values.sort((a, b) => b.value.localeCompare(a.value))
            }

            values = values.map(value => ({
              label: mapValueLabel(value.value),
              value: value.value,
              count: value.count,
            }))

            facets[facet.field_name] = {
              label: mapFieldName(facet.field_name),
              name: facet.field_name,
              values,
              // values: uniqBy([...(facets[facet.field_name]?.values || []), ...values], 'value'),
            }
          })
        })

        $logger.debug('Facets:', facets)

        return {
          auctionItems,
          meta: { facets, totalCount },
        }
      })
      .catch(error => {
        const msg = 'Typesense Error: search()'
        $logger.error(msg, { params, error })
        $rollbar.error(msg, { params, error })
      })
  },
}
