import appReturnRoutes from './appReturn.routes.js'

const accountRoute = {
  path: 'account',
  meta: { protected: true },
  children: [
    { path: '', component: () => import('src/pages/shared/account/AccountPage.vue') },
    { path: 'setup', component: () => import('src/pages/shared/account/AccountSetupPage.vue') },
    { path: 'watchlist', component: () => import('src/pages/shared/account/AccountWatchlistPage.vue') },
  ],
}

const authRoute = {
  path: 'auth',
  children: [
    { path: '', component: () => import('src/pages/shared/auth/AuthMainPage.vue') },
    { path: 'logout', component: () => import('src/pages/shared/auth/AuthLogoutPage.vue') },
  ],
}

const firebaseEmailAuthRoute = {
  path: '__/auth/action',
  component: () => import('src/pages/shared/auth/AuthEmailLinkSignInPage.vue'),
}

const retailerRoute = {
  path: 'r',
  children: [
    { path: '', component: () => import('src/pages/shared/retailer/RetailerMainPage.vue') },
    { path: 'noauth', component: () => import('src/pages/shared/retailer/RetailerNoAuthPage.vue') },
    { path: 'setup', component: () => import('src/pages/shared/retailer/RetailerSetupPage.vue') },
  ],
}

const shopRoute = {
  path: 'shop',
  children: [
    {
      path: '',
      meta: { bodyClass: 'rl-page' },
      component: () => import('pages/shared/MainShoppingPage.vue'),
    },
    { path: 'cart', meta: { protected: true, bodyClass: 'cart-page' }, component: () => import('pages/shared/CartPage.vue') },
    {
      path: 'checkout',
      meta: { protected: true, bodyClass: 'checkout-page' },
      component: () => import('pages/shared/CheckoutPage.vue'),
    },
    { path: 'order/:orderId/:exp?', meta: { protected: true }, component: () => import('pages/shared/OrderDetailPage.vue') },
    { path: 'product/:id', meta: { bodyClass: 'product-page' }, component: () => import('pages/shared/ProductDetailPage.vue') },
    { path: 'search', meta: { bodyClass: ['search-page', 'rl-page'] }, component: () => import('pages/shared/SearchPage.vue') },
  ],
}

export default [
  /* ======================================= */
  /*            SHOPPING ROUTES               */
  /* ======================================= */
  {
    path: '/',
    alias: ['/app'],
    component: () => import('src/layouts/shared/ShoppingLayout.vue'),
    children: [
      // Home Page
      { path: '', component: () => import('src/pages/shared/ContentfulDynamicPage.vue') },
      accountRoute,
      authRoute,
      firebaseEmailAuthRoute,
      retailerRoute,
      shopRoute,
    ],
  },

  // RETURN PAGE
  ...appReturnRoutes,
  // SPLASH PAGE
  {
    path: '/app/splash',
    component: () => import('layouts/app/AppSplashLayout.vue'),
    children: [
      { path: '', component: () => import('pages/app/AppSplashPageSingle.vue') },
      { path: 'peg', component: () => import('pages/app/AppSplashPagePeg.vue') },
      { path: 'triple', component: () => import('pages/app/AppSplashPageTriple.vue') },
    ],
  },
  // SHOPPING
  // {
  //   path: '/app/shop',
  //   component: () => import('layouts/app/AppShoppingLayout.vue'),
  //   children: [...shopRoute.children],
  // },

  /* ======================================== */
  /*             CATCHALL ROUTES              */
  /* ======================================== */
  // APP
  {
    path: '/app/:slug1/:slug2?/:slug3?/:slug4?',
    component: () => import('layouts/shared/ShoppingLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('src/pages/shared/ContentfulDynamicPage.vue'),
      },
    ],
  },

  // WEB
  {
    path: '/:slug1/:slug2?/:slug3?/:slug4?',
    component: () => import('src/layouts/shared/ShoppingLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('src/pages/shared/ContentfulDynamicPage.vue'),
      },
    ],
  },
]
