export default function () {
  const route = useRoute()
  const path = computed(() => route?.path || window.location.pathname)

  const isHomePage = computed(() => {
    // return path === '/' || path === '/app'
    return /^\/(app)?(\/)?$/.test(path.value)
  })

  const isAnyAccountPage = computed(() => {
    return /^\/(app\/)?account(\/)?/.test(path.value)
  })

  const isAccountMainPage = computed(() => {
    // return path === '/account' || path == '/app/account'
    return /^\/(app\/)?account(\/)?$/.test(path.value)
  })

  const isAccountSetupPage = computed(() => {
    // return path === '/account/setup' || path == '/app/account/setup'
    return /^\/(app\/)?account\/setup(\/)?$/.test(path.value)
  })

  const isAuthPage = computed(() => {
    // return path.startsWith('/auth')
    return /^\/auth(\/)?$/.test(path.value)
  })

  const isEmailLinkAuthPage = computed(() => {
    // return path.startsWith('/__/auth/action')
    return /^\/__\/auth\/action\/.+?$/.test(path.value)
  })

  const isCartPage = computed(() => {
    // return path.includes('/shop/cart')
    return /^\/(app\/)?shop\/cart(\/)?$/.test(path.value)
  })

  const isCheckoutPage = computed(() => {
    // return path.includes('/shop/checkout')
    return /^\/(app\/)?shop\/checkout(\/)?$/.test(path.value)
  })

  const isOrderDetailPage = computed(() => {
    // return path.startsWith('/shop/order/')
    return /^\/(app\/)?shop\/order\/.+$/.test(path.value)
  })

  const isProductPage = computed(() => {
    // return path.includes('/shop/product/')
    return /^\/(app\/)?shop\/product\/.+$/.test(path.value)
  })

  const isResultListPage = computed(() => {
    // return path.endsWith('/shop') || isSearchPage.value
    return /^\/(app\/)?shop(\/up-for-grabs)?(\/)?$/.test(path.value) || isSearchPage.value
  })

  const isRetailerPage = computed(() => {
    return /^\/r(\/)?$/.test(path.value)
  })

  const isSearchPage = computed(() => {
    // return path.endsWith('/shop/search')
    return /^\/(app\/)?shop\/search(\/)?$/.test(path.value)
  })

  const isShoppingRoute = computed(() => {
    // return path.includes('/shop') && !isCartPage.value && !isCheckoutPage.value
    return (/^\/(app\/)?shop(\/)?/.test(path.value) && !isCheckoutPage.value) || isHomePage.value || isWatchlistPage.value
  })

  const isWatchlistPage = computed(() => {
    // return path.endsWith('/watchlist')
    return /^\/(app\/)?account\/watchlist(\/)?$/.test(path.value)
  })

  return {
    isAnyAccountPage,
    isAccountMainPage,
    isAccountSetupPage,
    isAuthPage,
    isCartPage,
    isCheckoutPage,
    isEmailLinkAuthPage,
    isHomePage,
    isOrderDetailPage,
    isProductPage,
    isResultListPage,
    isRetailerPage,
    isSearchPage,
    isShoppingRoute,
    isWatchlistPage,
  }
}
