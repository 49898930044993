import { generateProxyJwt } from 'src/utils/jwt.util'
import { $logger } from 'boot/logger'

/**
 * Contenful proxy request wrapper
 * @param path Target path
 * @param options Request options
 */
const sendRequest = async (path, options = {}) => {
  const { method = 'GET', body = {}, headers = {} } = options
  const jwt = await generateProxyJwt()

  const payload = {
    method,
    headers: {
      'x-site-token': jwt,
      'Content-Type': 'application/json',
      'x-req-origin': 'peg-quasar', // custom header to bypass CORS issues without having to include multiple origins
      ...headers,
    },
  }

  if (method !== 'GET' && body) {
    payload.body = JSON.stringify(body)
  }

  try {
    let baseUrl = import.meta.env.VITE_NETLIFY_PROXY_URL
    // use v2 proxy for new edge-function requests
    const v2Routes = ['/gateway', '/geolocation']
    if (v2Routes.some(route => path.startsWith(route))) {
      baseUrl = baseUrl.replace('/v1', '/v2')
    }

    return fetch(`${baseUrl}${path}`, payload).then(resp => resp.json())
  } catch (error) {
    $logger.error('Netlify Proxy sendRequest', error)
    return Promise.reject(error)
  }
}

export const $proxy = {
  fetch: sendRequest,
}
