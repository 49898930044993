import { $fetch } from 'boot/fetch'
import { $logger } from 'boot/logger'
import { parseItem } from 'src/utils/product.util'

const ENDPOINT = '/auctionItems'

export default {
  /**
   * Create an auction item
   * @param {Object} itemData - Auction item data
   * @param {String} itemData.name - Name of item
   * @param {String} itemData.description - Description of item
   * @param {String} itemData.category - Category of item
   * @returns {Promise} - Created item
   */
  create(itemData) {
    return $fetch.peg(ENDPOINT, {
      method: 'POST',
      body: itemData,
    })
  },
  /**
   * Get multiple auction items
   * @param {Object} params
   * @param {String} params.status - AUCTION, SOLD, EXPIRED
   * @param {String} params.id - ID or list of IDs
   * @param {String} params.category - Category of item
   * @returns {Promise} - Array of items
   */
  getBulk(params = {}) {
    return $fetch
      .peg(ENDPOINT, { params })
      .then(data => {
        if (!data?.auctionItems) {
          return { auctionItems: [] }
        }

        return { ...data, auctionItems: data.auctionItems.map(parseItem) }
      })
      .catch(err => {
        $logger.error('Error getting auction items:', err)
        return { auctionItems: [] }
      })
  },
  /**
   * Get a single auction item
   * @param {String} id - ID of item
   * @param {Object} params - Additional query parameters
   * @returns {Promise} - Single item
   */
  getSingle(id, params = {}) {
    // return this.getBulk({ id, ...params }).then(({ items }) => items[0])
    return $fetch.peg(`${ENDPOINT}/${id}`, { params }).then(data => {
      if (!data.auctionItem) {
        return {}
      }

      return { ...data, auctionItem: parseItem(data.auctionItem) }
    })
  },
}
