export const SECOND_MS = 1000
export const MINUTE_MS = 60 * SECOND_MS
export const HOUR_MS = 60 * MINUTE_MS
export const DAY_MS = 24 * HOUR_MS

export const MINUTE_SEC = 60
export const HOUR_SEC = 60 * MINUTE_SEC
export const DAY_SEC = 24 * HOUR_SEC

/**
 * Format the timer value
 * @param {number} secondsLeft - time left in seconds
 * @returns {string} formatted timer value
 */
export function formatTimer(secondsLeft, defaultUnit = 'days') {
  const days = Math.floor(secondsLeft / DAY_SEC)
  const hours = Math.floor(secondsLeft / HOUR_SEC)
  const minutes = Math.floor((secondsLeft % HOUR_SEC) / MINUTE_SEC)
  const seconds = Math.floor(secondsLeft % MINUTE_SEC)

  let value = ''
  let unit = ''
  if (days > 0 && defaultUnit === 'days') {
    // hours = Math.floor((secondsLeft % DAY_SEC) / HOUR_SEC)
    value = `${days} day${days > 1 ? 's' : ''}`
    unit = 'day'
  } else if (hours > 0) {
    value = `${hours}h`
    unit = 'hour'

    // add half hour if more than 30 minutes
    // if (hours > 1 || minutes > 30) {
    //   value += '.5'
    //   unit = 'hour'
    // }

    // add minutes if under 24 hours
    if (hours < 24 && hours >= 1) {
      value += ` ${minutes}m`
      // eslint-disable-next-line no-unused-vars
      unit = 'hour'
    } else if (hours < 1) {
      value = `${minutes}m`
      // unit = 'minute'
    }
  } else {
    return `${minutes.toString().padStart(2, '0')}m ${seconds.toString().padStart(2, '0')}s`
  }

  // return `${value} ${unit}${value > 1 ? 's' : ''}`
  return `${value}`
}

/**
 * Check if the timer should start
 * @param {number} seconds - time left in seconds
 * @returns {boolean} should start timer
 */
export function shouldStartTimer(seconds) {
  return seconds > 0 && seconds <= DAY_SEC
}
