import { SignJWT, jwtVerify, decodeJwt as joseDecodeJwt } from 'jose'

/**
 * Encode a JWT
 * @param {Object} payload - The payload to encode
 * @param {String} secret - The secret to encode the JWT with
 * @returns {String} - The encoded JWT
 */
export async function encodeJwt(payload, secret) {
  const encodedSecret = new TextEncoder().encode(secret)
  const jwt = await new SignJWT(payload).setProtectedHeader({ alg: 'HS256' }).sign(encodedSecret)

  return jwt
}

/**
 * Decode a JWT with validation
 * @param {String} token - The JWT to decode
 * @param {String} secret - The secret to decode the JWT with
 * @returns {Object} - The decoded payload
 */
export async function validDecodeJwt(token, secret) {
  const secretBytes = new TextEncoder().encode(secret)
  const { payload } = await jwtVerify(token, secretBytes)
  return payload
}

/**
 * Decode a JWT without validation
 * @param {String} token - The JWT to decode
 * @returns {Object} - The decoded payload
 */
export function decodeJwt(token) {
  return joseDecodeJwt(token)
}

/**
 * Generate a JWT for the proxy
 * @param {Object} data - The data to include in the JWT
 * @returns {String} - The generated JWT
 */
export const generateProxyJwt = (data = {}) => {
  const payload = { host: 'www.peg.com', data }
  const secret = import.meta.env.VITE_NETLIFY_PROXY_SECRET

  return encodeJwt(payload, secret)
}
