/*
  The routingMap is a collection of routes that the user will take to process a return item.
  The map is stored on each item.
  The map can be updated from tile selectors - need explanation from user why they selected the tile

  A dynamic import map of components used in done in AppReturnProcessItem.vue.
  Make sure all components used in the routing map are imported there for now until a dynamic import is implemented.

  A routing map looks like this:
  {
    routeSlug: {
      // component configuration
      component: {
        name: {string}, // Vue component name in components/app/return folder
        title: {string},
        content: {string},
      },
      // routing configuration
      routing: {
        yes: {string}, // route slug
        no: {string}, // route slug
        next: {string}, // route slug
        dynamicSlugPrefix: {string} // prefix for dynamic routing components
      }
    }
    ...
  }
*/

import { useId } from 'quasar' // useId() was not working without the import
import { $logger } from 'boot/logger'
import { $pegApi } from 'src/boot/peg-api'
import { cloneDeep } from 'lodash-es'
import { RETURN_ITEM_PROCESS_DATA } from 'src/config/returnItemProcessData'
import { MISSING_IMAGE } from 'src/constants/image.const'

export const useCustomerReturnStore = defineStore('customerReturn', () => {
  const $q = useQuasar()

  const sessionStore = useSessionStore()
  const { location, user } = storeToRefs(sessionStore)

  const returnSessionId = ref(null) // an ID that both the FE and backend have access to for temporary storage of images
  const retailer = ref(null)
  const retailerId = ref(null)
  const orderEmail = ref(user.value?.email || null) // email address on order
  const orderNumber = ref(null)
  const termsOfServiceAgreed = ref(user.value?.termsAccepted || false)
  const order = ref(null)
  const itemsToProcess = ref([]) // items that need to be processed
  const processingItemIndex = ref(null) // track the index of the item being processed
  const routingBehavior = ref([]) // track all routes user took per item
  const done = ref(false) // summary has been reached and all items have been processed
  const disableRouting = ref(false) // disable routing/actions

  // data from submitting the return and used to display confirmation
  const confirmation = ref({
    returnItems: [],
    auctionItems: [],
    inStoreCredits: [],
  })

  const items = computed(() => order.value?.lineItems) // all items on the original order, used for choose items
  const isSingleItemOrder = computed(() => items.value?.length === 1) // only one item on the order
  const isSingleItemReturnable = computed(() => (isSingleItemOrder.value ? items.value[0].quantityAvailableToReturn > 0 : false)) // single item can be returned

  const item = computed(() => itemsToProcess.value[processingItemIndex.value]) // current item being processed
  const boxImages = computed(() => item.value?.images.filter(i => i.mediaType === 'BOX'))
  const productImages = computed(() => item.value?.images.filter(i => i.mediaType === 'PRODUCT'))
  // only take product pictures if the item is not damaged and the box is opened
  const takeProductPictures = computed(
    () =>
      item.value?.returnQuestions.find(q => q.slug === 'boxes-opened' && q.answer === 'YES') &&
      item.value?.returnReasons.some(r => r.type === 'DAMAGED') === false
  )
  const nextItem = computed(() => {
    const max = itemsToProcess.value.length - 1
    const next = processingItemIndex.value + 1
    return next > max ? null : itemsToProcess.value[next]
  })

  /**
   * @description Reset the state of the store
   * @function resetState
   * @param {Object} options
   * @param {Boolean} [options.clearConfirmation=false] - clear the confirmation data
   * @param {Boolean} [options.keepRetailer=false] - keep the retailer data
   */
  function resetState({ clearConfirmation = false, keepRetailer = false } = {}) {
    returnSessionId.value = null
    if (!keepRetailer) {
      retailer.value = null
      retailerId.value = null
    }
    orderNumber.value = null
    order.value = null
    itemsToProcess.value = []
    processingItemIndex.value = null
    done.value = false
    if (clearConfirmation) {
      confirmation.value.returnItems = []
      confirmation.value.auctionItems = []
      confirmation.value.inStoreCredits = []
    }
    // NOTE: routingBehavior is not reset as it is used to track all routes taken by the user during the session
  }

  /**
   * @description Request a new OTP
   * @function requestNewOtp
   * @param {String} retailerId
   * @param {String} orderNumber
   * @returns {Object}
   */
  function requestNewEmailOtp(retailerId, orderNumber) {
    return getOrder(retailerId, orderNumber)
  }

  /**
   * @description Get order data and see what kind of verification is needed
   * @function getOrder
   * @param {String} retailerId
   * @param {String} orderNumber
   * @returns {Promise}
   */
  async function getOrder(retailerId, orderNumber) {
    order.value = null

    const response = await $pegApi.Retailers.getRetailerOrder(retailerId, orderNumber, {
      latitude: location.value.latitude,
      longitude: location.value.longitude,
      raw: true,
    })

    $logger.debug('getRetailerOrder response -> ', response)
    if (response.statusCode === 200) {
      order.value = {
        retailer: retailerId,
        orderNumber: orderNumber,
        ...response.data.order,
        lineItems: response.data.order.lineItems.map(item => ({
          ...item,
          selectedQuantity: 1,
          selected: false,
          image: item.image || { ...MISSING_IMAGE },
        })),
      }
    }
    return response
  }

  /**
   * @description an item being processed was updated
   * @function updateItemToProcess
   * @param {Object} item
   * @param {Number} index
   */
  function updateItemToProcess(item, index) {
    const items = itemsToProcess.value
    items[index] = item
    itemsToProcess.value = [...items]
  }

  /**
   * @description Update item as a user selects it or updates quantity being returned
   * @function updateItem
   * @param {Object} item
   * @param {Number} index
   */
  function updateOrderItem(item, index) {
    const items = order.value.lineItems
    items[index] = item
    order.value.lineItems = [...items]
  }

  /**
   * @description Set the index of the item being processed
   * @function setProcessingItemIndex
   * @param {Number} index
   */
  function setProcessingItemIndex(index) {
    processingItemIndex.value = index
  }

  /**
   * @description Set the retailer data
   * @function setRetailer
   * @param {Object} retailerData
   */
  function setRetailer(retailerData) {
    retailer.value = { ...retailerData }
    retailerId.value = retailerData.id
  }

  /**
   * @description Disable routing
   * @function setDisableRouting
   * @param {Boolean} value
   */
  function setDisableRouting(value) {
    disableRouting.value = value
  }

  /**
   * @description Start the return process after items have been selected
   * @function startReturn
   */
  async function initReturn() {
    itemsToProcess.value = []
    returnSessionId.value = null

    const { returnSession, success } = await $pegApi.ReturnSessions.start()
    if (!success) {
      $q.notify({
        message: 'Failed to establish a return session. Please try again.',
        type: 'error-long',
      })
      return false
    }
    returnSessionId.value = returnSession.id

    // break out each quantity into single processable items with a unique ids
    for (let t = 0; t < items.value.length; t++) {
      let item = items.value[t]
      if (!item.selected || item.selectedQuantity === 0) {
        continue
      }
      for (let i = 1; i <= item.selectedQuantity; i++) {
        const catId = item?.category?.id || null
        const { appReturnFlow, success } = await $pegApi.AppReturnFlow.getSingle({
          standardCategoryId: catId,
        })
        if (!success) {
          $q.notify({
            message: 'There was an error initializing the return process. Please try again.',
            type: 'error-long',
          })
          return false
        }
        itemsToProcess.value.push({
          ...cloneDeep(item),
          ...cloneDeep(RETURN_ITEM_PROCESS_DATA),
          id: useId(),
          returnCount: i,
          flowName: appReturnFlow.flowName,
          startAtSlug: appReturnFlow.startAtSlug,
          routingMap: { ...cloneDeep(appReturnFlow.routingMap) },
          routingHistory: [],
        })
      }
    }
  }

  /**
   * @description Submit the return data
   * @function submitReturn
   */
  async function submitReturn() {
    // map itemsToProcess to the API format
    const mappedItems = itemsToProcess.value.map(item => {
      return {
        product: {
          platformProductId: item.platformProductId,
          platformVariantId: item.platformVariantId,
          platformSKU: item.platformSKU,
          name: item.name,
          image: item.image?.url || '',
          price: parseFloat(item.price),
          discountedPrice: parseFloat(item.discountedPrice) || null,
          description: item.description,
          productPageUrl: item.productPageUrl,
          productType: item.productType,
          collection: item.collection,
          category: item.category,
        },
        appReturnFlowName: item.flowName,
        numberOfBoxes: item.numberOfBoxes,
        returnReasons: [...item.returnReasons],
        returnQuestions: [...item.returnQuestions],
        defectiveDetails: item.defectiveDetails,
        preferenceDetails: item.preferenceDetails,
        damageDetails: item.damageDetails,
        images: item.images.map(i => i.id), // pass only IDs, the backend will get them by the ID
      }
    })

    done.value = true

    // submit to API
    const { returnItems, auctionItems, inStoreCredits, success } = await $pegApi.Retailers.submitReturn(
      retailer.value.id,
      orderNumber.value,
      { items: mappedItems }
    )

    confirmation.value.returnItems = returnItems
    confirmation.value.auctionItems = auctionItems
    confirmation.value.inStoreCredits = inStoreCredits

    if (success) {
      returnSessionId.value = null
    }

    return success
  }

  /**
   * @description Prepare the summary data for display
   * @function prepareSummary
   * @returns {Object}
   */
  function prepareSummary() {
    itemsToProcess.value = itemsToProcess.value.map(item => {
      let i = { ...item }

      // if the slug is not in the routingHistory, then it should be discarded from returnQuestions
      i.returnQuestions = i.returnQuestions.filter(q => i.routingHistory.includes(q.slug))

      // remove box and product images if the pictures slug is not in the routingHistory
      const hasPicturesSlug = item.routingHistory.includes('pictures')
      if (!hasPicturesSlug) {
        i.images = i.images.filter(image => !['BOX', 'PRODUCT'].includes(image.mediaType))
      }

      const reasonTypes = i.returnReasons.map(r => r.type)
      if (!reasonTypes.includes('DAMAGED')) {
        i.damageDetails = ''
      }
      if (!reasonTypes.includes('DEFECTIVE')) {
        i.defectiveDetails = ''
      }
      if (!reasonTypes.includes('PREFERENCE')) {
        i.preferenceDetails = ''
      }
      return i
    })
  }

  /**
   * @description Upload media to the server
   * @function uploadMedia
   * @param {String} mediaType
   * @param {String} image
   * @returns {Promise}
   */
  async function uploadMedia(mediaType, image) {
    const { success, returnSessionMedia } = await $pegApi.ReturnSessionMedia.add({
      mediaType,
      image,
      description: mediaType === 'DAMAGE' ? 'damage' : null,
    })
    if (!success) {
      $q.notify({
        message: 'There was an error saving the photo. Please try again.',
        type: 'error',
      })
      return
    }
    const { id, url } = returnSessionMedia
    return {
      id,
      mediaType,
      image: url,
      src: image,
    }
  }

  /**
   * @description Lookup retailer by email and order number
   * @function lookupRetailer
   * @return {Promise}
   */
  async function lookupRetailerByOrder({ email, orderNumber }) {
    $logger.debug('lookupRetailerByOrder', { email, orderNumber })
    let retailer = null
    const { retailerId } = await $pegApi.Retailers.orderLookup(email, orderNumber)
    if (retailerId) {
      const { retailers } = await $pegApi.Retailers.getAll({ id: retailerId })
      if (retailers.length) {
        retailer = retailers[0]
      }
    }
    return retailer
  }

  return {
    // data
    returnSessionId,
    orderEmail,
    retailer,
    retailerId,
    orderNumber,
    order,
    done,
    item,
    items,
    itemsToProcess,
    isSingleItemOrder,
    isSingleItemReturnable,
    boxImages,
    productImages,
    takeProductPictures,
    nextItem,
    processingItemIndex,
    routingBehavior,
    disableRouting,
    confirmation,
    termsOfServiceAgreed,
    // methods
    getOrder,
    updateOrderItem,
    setProcessingItemIndex,
    updateItemToProcess,
    submitReturn,
    resetState,
    requestNewEmailOtp,
    initReturn,
    setRetailer,
    prepareSummary,
    setDisableRouting,
    uploadMedia,
    lookupRetailerByOrder,
  }
})
