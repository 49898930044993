import { $fetch } from 'boot/fetch'
import WatchlistItems from './watchlistItems'

const ENDPOINT = '/users'

export default {
  create(user) {
    return $fetch.peg(ENDPOINT, {
      method: 'POST',
      body: user,
    })
  },
  get() {
    return $fetch.peg(ENDPOINT).then(data => {
      // handle 404 has a successful response to ensure a new user gets created in session.store.js
      if (data.statusCode === 404) {
        return { success: true, user: null }
      }

      return data
    })
  },
  update(user) {
    return $fetch.peg(ENDPOINT, {
      method: 'PUT',
      body: user,
    })
  },
  addWatchlistItem(auctionItemId) {
    return WatchlistItems.add(auctionItemId)
  },
  getWatchlistItems(params = {}) {
    return WatchlistItems.getAll(params)
  },
  removeWatchlistItem(id) {
    return WatchlistItems.remove(id)
  },
  /**
   * @description Request a phone OTP for logged in user
   * @function requestPhoneOtp
   * @returns {Promise}
   */
  requestPhoneOtp() {
    return $fetch.peg(`${ENDPOINT}/sendPhoneOTP`, {
      raw: true,
      method: 'POST',
      body: {},
    })
  },
  /**
   * @description Verify a phone OTP for the logged in user
   * @function verifyPhoneOtp
   * @returns {Promise}
   */
  verifyPhoneOtp(otp) {
    return $fetch.peg(`${ENDPOINT}/verifyPhoneOTP`, {
      raw: true,
      method: 'PUT',
      body: { otp },
    })
  },
}
