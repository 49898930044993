import { $fetch } from 'boot/fetch'

const ENDPOINT = '/auctionItems/{{id}}/bids'

function buildPath(id) {
  return ENDPOINT.replace('{{id}}', id)
}

export default {
  /**
   * Get bids for an item
   * @param {String} id - ID of item
   * @returns {Promise} - Array of bids
   */
  getAll(itemId) {
    return $fetch.peg(buildPath(itemId))
  },
}
