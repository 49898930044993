import { defineRouter } from '#q-app/wrappers'
import { Platform } from 'quasar'
import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'
import routes from './routes'
import { useNavigationStore } from 'stores/navigation.store'
import { useSessionStore } from 'stores/session.store'
import { $logger } from 'boot/logger'

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default defineRouter(function ({ store }) {
  const navigationStore = useNavigationStore(store)
  const sessionStore = useSessionStore(store)

  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : process.env.VUE_ROUTER_MODE === 'history'
      ? createWebHistory
      : createWebHashHistory

  const Router = createRouter({
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        // $logger.debug('SCROLL BEHAVIOR', savedPosition)
        return savedPosition
      } else {
        return { top: 0 }
      }
    },
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE),
  })

  // navigation guards
  Router.beforeEach(async (to, from, next) => {
    $logger.debug('ROUTE', { from, to })

    // need to always ensure auth is initialized before proceeding
    if (!sessionStore.authIsInitialized) {
      $logger.debug('WAITING FOR AUTH')
      await sessionStore.awaitAuthInit()
    }

    to.meta = to.meta || {}
    to.meta.previous = from

    // add/remove class to body for specified routes
    if (from.meta?.bodyClass) {
      if (!Array.isArray(from.meta.bodyClass)) {
        from.meta.bodyClass = [from.meta.bodyClass]
      }
      from.meta.bodyClass.forEach(c => document.body.classList.remove(c))
    }
    if (to.meta?.bodyClass) {
      if (!Array.isArray(to.meta.bodyClass)) {
        to.meta.bodyClass = [to.meta.bodyClass]
      }
      to.meta.bodyClass.forEach(c => document.body.classList.add(c))
    }

    let prefix = ''

    // handle for native mobile paths
    if (from.path.startsWith('/app') && !to.path.startsWith('/app')) {
      prefix = '/app'
    }

    const nextPath = `${prefix}${to.path}`

    $logger.debug('NEXT PATH', nextPath, to.meta)

    // redirect to main page if session is locked down and not one of the allowed routes
    if (
      sessionStore.sessionIsLockedDown &&
      !/^\/(app)?(\/)?$/.test(to.path) && // home page
      !/\/auth(\/)?.*/.test(to.path) && // auth-related pages
      // !/\/account(\/)?.*/.test(to.path) && // account-related pages
      !/^\/app\/splash(\/)?$/.test(to.path) && // app splash page
      !/^\/app\/return(\/)?.*/.test(to.path) // return flow
    ) {
      return next(Platform.is.nativeMobile ? '/app/' : '/')
    }

    if (to.meta?.protected && !sessionStore.userIsAuthenticated) {
      if (!sessionStore.userIsAuthenticated) {
        if (!prefix && to.path.startsWith('/app')) {
          prefix = '/app'
        }

        $logger.debug('REDIRECT TO AUTH', prefix)
        return next(`${prefix}/auth/?ref=${to.fullPath}`)
      }
    }

    if (sessionStore.userIsAuthenticated) {
      if (to.path === '/auth/') {
        $logger.debug('REDIRECT TO ROOT')
        return next(`${prefix}/`)
      }

      // redirect to setup from cart/checkout if not completed
      if (!sessionStore.accountSetupCompleted && /(\/cart|\/checkout)/.test(to.path)) {
        $logger.debug('REDIRECT TO SETUP')
        return next(`/account/setup/?ref=${to.fullPath}`)
      }

      // redirect to account if setup already completed
      if (sessionStore.accountSetupCompleted && sessionStore.user?.emailVerified && /\/account\/setup(\/)?/.test(to.path)) {
        $logger.debug('REDIRECT TO ACCOUNT')
        return next(to.query?.ref || '/account/')
      }
    }

    // handle navigation history
    // don't append if coming from return flow
    if (Platform.is.nativeMobile) {
      const toBaseRoute = /^\/app(\/)?$/.test(to.path)
      const splashReturnRegExp = new RegExp('^/app/(splash|return)')
      const fromSplashReturnRoute = splashReturnRegExp.test(from.path)
      const toSplashReturnRoute = splashReturnRegExp.test(to.path)

      if (fromSplashReturnRoute || toSplashReturnRoute || toBaseRoute) {
        navigationStore.clearHistory()
        if (fromSplashReturnRoute) {
          navigationStore.appendHistory(nextPath)
        }
      } else if (to.path === navigationStore.previousRoute) {
        navigationStore.popHistory()
      } else if (to.path !== navigationStore.currentRoute) {
        navigationStore.appendHistory(nextPath)
      }
    }

    if (to.path === nextPath) {
      $logger.debug('GO TO NEXT ROUTE', { from, to })
      return next()
    }

    $logger.debug('REDIRECT TO NEXT PATH', nextPath, to.query, to.hash)
    return next({ path: nextPath, query: to.query, hash: to.hash })
  })

  return Router
})
