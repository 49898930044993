import { $fetch } from 'boot/fetch'

const ENDPOINT = '/cart'

export default {
  /**
   * Add an item to the cart
   * @param {Object} payload - The item to add
   * @returns {Promise} The result of the request
   */
  addItem(payload) {
    return $fetch.peg(ENDPOINT, {
      method: 'POST',
      body: payload,
    })
  },
  /**
   * Get the current user's cart
   * @param {Object} params - Query parameters, like zip code
   * @returns {Promise<Array>} The user's cart items
   */
  get(params = {}) {
    return $fetch.peg(ENDPOINT, { params })
  },
  /**
   * Remove an item from the cart
   * @param {string} id - The ID of the item to remove
   * @returns {Promise} The result of the request
   */
  remove(id) {
    return $fetch.peg(`${ENDPOINT}/${id}`, {
      method: 'DELETE',
    })
  },
}
