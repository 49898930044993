import validator from '@rushrecommerce/util-validator'

export function httpsImageUrl(v) {
  return (
    !v ||
    /^https:\/\/[\da-z.-/]+\.([\da-z.-/]+).*(jp(eg|g)|png|ico|svg)$/i.test(v) ||
    'Must be a fully qualified image URL from a secure domain. Accepted formats: jpg, jpeg, png, ico, svg'
  )
}

export function httpsUrl(v) {
  return !v || /^https:\/\/.*?\.?.+(co|com|net|org)\/?.*$/i.test(v) || 'Must be a fully qualified URL from a secure domain.'
}

export function isValidEmail(email) {
  return validator.isValidEmail(email) || 'Please enter a valid email'
}

export function isValidPhone(phone) {
  return validator.isValidPhone(phone) || 'Please enter a valid phone number'
}

export function isValidZip(zip) {
  return validator.isValidZip(zip) || 'Please enter a valid zip code'
}

export function required(fieldName = 'Input') {
  return value => !!value || `${fieldName} is required.`
}

export function addressIsComplete(address, options = {}) {
  const { includeName = true, includePhone = false } = options
  const { firstName, lastName, address1, city, state, zip, phone } = address || {}
  return Boolean(
    (!includeName || (firstName && lastName)) &&
      (!includePhone || phone) &&
      address1 &&
      city &&
      state &&
      validator.isValidZip(zip)
  )
}
