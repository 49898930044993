import { $fetch } from 'boot/fetch'

const ENDPOINT = '/appReturnFlows'

export default {
  /**
   * @description Get a single app return flow
   * @function getSingle
   * @param {Object} params
   * @param {String} [params.standardCategoryId] - standard category ID of a product
   * @returns {Promise} - { flowName, startAtSlug, routingMap }
   */
  getSingle(params = {}) {
    return $fetch.peg(`${ENDPOINT}`, {
      params,
    })
  },
}
