import { $fetch } from 'boot/fetch'
import { DELIVERY } from 'src/constants/fulfillment.const'
import { parseItem } from 'src/utils/product.util'

const ENDPOINT = '/orders'

function parseOrderItems(items) {
  return items.map(item => {
    item.hasDeliveryFulfillment = item.fulfillmentMethod === DELIVERY
    let status = 'PROCESSING'

    if (item.hasDeliveryFulfillment) {
      if (item.fulfilled) {
        status = 'SHIPPED'
      }
    } else {
      if (item.fulfilled) {
        status = 'READY_FOR_PICKUP'
      }

      if (item.pickedUp) {
        status = 'PICKED_UP'
      }
    }

    item.status = status

    // flatten item data and parse
    return parseItem({ ...item.auctionItem, ...item })
  })
}

function parseOrders(orders) {
  return orders.map(order => {
    order.lineItems = parseOrderItems(order.lineItems)

    let status = 'PROCESSING'

    if (order.lineItems.every(item => item.fulfilled)) {
      status = 'FULFILLED'
    } else if (order.lineItems.some(item => item.fulfilled)) {
      status = 'PARTIALLY_FULFILLED'
    }

    order.status = status
    order.totalOriginalLineItemPrice = order.lineItems.reduce((acc, item) => acc + item.originalPrice, 0)
    order.totalSavings = order.totalOriginalLineItemPrice - order.totalPrice

    return order
  })
}

export default {
  async getBulk(params = {}) {
    if (!params.limit) {
      params.limit = 5
      params.offset = 0
    }
    return $fetch.peg(ENDPOINT, { params }).then(resp => {
      resp.orders = parseOrders(resp.orders)
      return resp
    })
  },
  async getSingle(id) {
    return $fetch.peg(`${ENDPOINT}/${id}`).then(resp => {
      if (resp.order) {
        resp.order = parseOrders([resp.order])[0]
      }
      return resp
    })
  },
}
