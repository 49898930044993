//import { $pegApi } from 'src/boot/peg-api'

/**
 * @description Check if the order data is valid
 * @function checkValidOrderData
 * @param {Object} to
 * @param {Object} from
 * @param {Function} next
 */
function checkValidOrderData(to, from, next) {
  const customerReturnStore = useCustomerReturnStore()
  const { retailer, orderNumber } = storeToRefs(customerReturnStore)
  if (!retailer.value && !orderNumber.value && to.path !== '/app/return' && to.path !== '/app/return/confirmation') {
    return next('/app')
  }
  return next()
}

/**
 * @description Check if the user can enter the return process
 * @function checkEntrance
 * @param {Object} to
 * @param {Object} from
 * @param {Function} next
 */
async function checkEntrance(to, from, next) {
  const skipReturnTutorial = localStorage.getItem('peg_skip_how_it_works') === '1'
  if (!skipReturnTutorial) {
    return next({ ...to, path: '/app/return/how-it-works' })
  }
  return next({ ...to, path: '/app/return/lookup-order' })
}

/**
 * /app/return routes
 * @type {Array}
 * @property {String} path
 * @property {Function} component
 * @property {Function} [beforeEnter]
 * @property {Object} [meta]
 * @property {Object} [meta.layout]
 * @property {Boolean} [meta.layout.showActionButtons]
 * @property {Boolean} [meta.layout.noContentPadding]
 * @property {Boolean} [meta.layout.showCurrentItem]
 * @property {Boolean} [meta.layout.showBottomNav]
 * @property {Object} [meta.layout.header]
 * @property {Boolean} [meta.layout.header.showSlogan]
 * @property {Boolean} [meta.layout.header.showBackButton]
 * @property {Boolean} [meta.layout.header.showOrderNumber]
 * @property {Boolean} [meta.layout.header.showUserGreeting]
 *
 */

export default [
  {
    path: '/app/return',
    component: () => import('src/layouts/app/AppReturnLayout.vue'),
    meta: {
      layout: {
        header: {
          showSlogan: true,
        },
      },
    },
    children: [
      {
        path: '',
        beforeEnter: checkEntrance,
        component: () => import('src/pages/app/return/AppReturnPage.vue'),
      },
      // order lookup
      {
        path: 'lookup-order',
        component: () => import('src/pages/app/return/AppReturnLookupOrder.vue'),
        meta: {
          layout: {
            showBottomNav: true,
            header: {
              showSlogan: true,
            },
          },
        },
      },
      // contentful
      {
        path: 'how-to-start',
        component: () => import('src/pages/app/return/AppReturnHowToStartReturn.vue'),
        meta: {
          layout: {
            showBottomNav: true,
            contentful: true,
          },
        },
      },
      {
        path: 'how-it-works',
        component: () => import('src/pages/app/return/AppReturnHowItWorks.vue'),
        meta: {
          layout: {
            showBottomNav: true,
            contentful: true,
          },
        },
      },
      {
        path: 'what-is-peg',
        component: () => import('src/pages/app/return/AppReturnWhatIsPeg.vue'),
        meta: {
          layout: {
            showBottomNav: true,
            contentful: true,
          },
        },
      },
      // choose items
      {
        path: 'items',
        name: 'AppReturnChooseItems',
        component: () => import('src/pages/app/return/AppReturnChooseItems.vue'),
        beforeEnter: checkValidOrderData,
        meta: {
          layout: {
            showActionButtons: true,
            header: {
              showOrderNumber: false,
              showUserGreeting: true,
            },
          },
        },
      },
      // processing an item
      {
        path: 'item/:idx/:slug',
        component: () => import('pages/app/return/AppReturnProcessItem.vue'),
        beforeEnter: checkValidOrderData,
        meta: {
          confirmExit: true,
          layout: {
            showActionButtons: true,
            showCurrentItem: true,
            header: {
              showBackButton: true,
              showOrderNumber: true,
            },
          },
        },
      },
      // processing item summary
      {
        path: 'summary',
        component: () => import('src/pages/app/return/AppReturnSummary.vue'),
        beforeEnter: checkValidOrderData,
        meta: {
          confirmExit: true,
          layout: {
            showActionButtons: true,
            header: {
              showOrderNumber: true,
            },
          },
        },
      },
      // processing item confirmation page
      {
        path: 'confirmation',
        component: () => import('src/pages/app/return/AppReturnConfirmation.vue'),
        meta: {
          layout: {
            showBottomNav: true,
            noContentPadding: true,
            header: {
              showSlogan: true,
            },
          },
        },
      },
    ],
  },
]
