import { $fetch } from 'boot/fetch'

const ENDPOINT = '/cityState'

export default {
  /**
   * Get the city and state for a given zip code
   * @param {string} zip - The zip code to look up
   * @returns {Promise<Object>} The city and state for the given zip code
   */
  getByZip(zip) {
    return $fetch.peg(ENDPOINT, { params: { zip } })
  },
}
